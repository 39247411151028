var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","max-width":"600px"},model:{value:(_vm.showGoDialog),callback:function ($$v) {_vm.showGoDialog=$$v},expression:"showGoDialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("DailyKM")))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('Vehicle Type'),"items":_vm.vehicleTypeList,"item-text":"label","item-value":"value","filled":"","required":"","rules":_vm.requiredRules},model:{value:(_vm.selected.vehicleType),callback:function ($$v) {_vm.$set(_vm.selected, "vehicleType", $$v)},expression:"selected.vehicleType"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Start') + ' KM',"type":'number',"required":"","rules":[_vm.kmNumRules]},model:{value:(_vm.selected.startKM),callback:function ($$v) {_vm.$set(_vm.selected, "startKM", $$v)},expression:"selected.startKM"}})],1)],1),(_vm.selected)?[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12"}},[_c('gennera-picture-input',{ref:"pictureInputStartPhoto",staticClass:"my-picture-input",attrs:{"crop":false,"width":"500","height":"300","size":"5","prefill":_vm.getImageUri(
                        _vm.selected.startPhoto.static
                          ? _vm.selected.startPhoto.static
                          : null
                      ),"accept":"image/jpeg,image/png","button-class":"btn btn-outline-info","aspect":"Landscape","customStrings":{
                      upload: this.$t('uploading_image'),
                      change: this.$t('change'),
                      tap: 'toma una foto'
                    },"capture":`camera`},on:{"change":_vm.onPictureStartPhoto}})],1)],1)]:_vm._e()],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();_vm.showGoDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{ref:"btnSave",attrs:{"color":"blue darken-1","text":"","loading":_vm.isSaving},on:{"click":function($event){return _vm.onSaveClick(_vm.selected)}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }