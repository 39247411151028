<template>
  <div>
    <v-dialog scrollable v-model="showGoDialog" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ $t("DailyKM") }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-autocomplete
                    v-model="selected.vehicleType"
                    v-bind:label="$t('Vehicle Type')"
                    :items="vehicleTypeList"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-text-field
                    v-model="selected.startKM"
                    v-bind:label="$t('Start') + ' KM'"
                    :type="'number'"
                    required
                    :rules="[kmNumRules]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <template v-if="selected">
                <v-row>
                  <v-col cols="12" sm="12" class="text-center">
                    <gennera-picture-input
                      :crop="false"
                      class="my-picture-input"
                      ref="pictureInputStartPhoto"
                      width="500"
                      height="300"
                      size="5"
                      :prefill="
                        getImageUri(
                          selected.startPhoto.static
                            ? selected.startPhoto.static
                            : null
                        )
                      "
                      accept="image/jpeg,image/png"
                      button-class="btn btn-outline-info"
                      aspect="Landscape"
                      :customStrings="{
                        upload: this.$t('uploading_image'),
                        change: this.$t('change'),
                        tap: 'toma una foto'
                      }"
                      @change="onPictureStartPhoto"
                      :capture="`camera`"
                    >
                    </gennera-picture-input>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="showGoDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import PictureInput from "vue-picture-input";
import moment from "moment";
import { mapGetters } from "vuex";
import GenneraPictureInput from "@/components/GenneraPictureInput.vue";

export default {
  name: "DialogStartKM",
  components: {
    PictureInput,
    GenneraPictureInput
  },
  props: [
    "value",
    "vehicleTypeList",
    "session",
    "current_km_data",
    "mainRoute"
  ],
  data: function() {
    return {
      selected: null,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      kmNumRules: v => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Este campo debe estar por encima de 0";
      },
      isSaving: false,
      isSelecting: false,
      defaultButtonText: "Select ..."
    };
  },
  computed: {
    ...mapGetters("myroute", ["deviceInformation"]),
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
    showGoDialog: {
      get() {
        this.init();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    moment,
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        vehicleType: _.get(item, "vehicleType"),
        startKM: _.get(item, "startKM"),
        startPhoto: {
          img: null,
          static: _.get(item, "startPhoto")
        }
      };
      if (!model.vehicleType) model.vehicleType = "OWNVEHICLE";
      return model;
    },
    onPictureStartPhoto() {
      this.selected.startPhoto.img = this.$refs["pictureInputStartPhoto"].file;
    },
    async saveForEmptyKM() {
      this.selected = this.createSelectedModel(this.current_km_data);
      if (this.selected) {
        this.selected.startKM = 0;
        let body = _.omit(this.selected, ["startPhoto"]);
        body.userId = this.session.userId;
        body.date = this.session.date;
        let logDate = moment(new Date())
          .local()
          .format("YYYY-MM-DD");
        body = {
          ...body,
          isStarted: true,
          isPaused: false,
          logDate,
          currentDate: new Date(),
          endableMoment: new Date(logDate + " 23:59:59"),
          routeId: this.mainRoute.id
        };
        let response = await ApiService.post(`worksession/save_start_km`, body);
        return response;
      }
      return null;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        try {
          this.isSaving = true;
          let body = _.omit(item, ["startPhoto"]);
          body.userId = this.session.userId;
          body.date = this.session.date;
          let imgStart = item.startPhoto.img;
          if (imgStart) {
            let { data } = await ApiService.uploadImage(imgStart);
            body.startPhotoId = data.id;
          } else if (!item.startPhoto.static) {
            logError("Se requiere una foto de inicio.");
            this.isSaving = false;
            return;
          }
          let logDate = moment(new Date())
            .local()
            .format("YYYY-MM-DD");
          body = {
            ...body,
            isStarted: true,
            isPaused: false,
            logDate,
            currentDate: new Date(),
            endableMoment: new Date(logDate + " 23:59:59"),
            routeId: this.mainRoute.id
          };
          let response = await ApiService.post(
            `worksession/save_start_km`,
            body
          );
          this.$emit("savingStartKM", response);
          this.isSaving = false;
        } catch (error) {
          this.isSaving = false;
        }
      }
    },
    init() {
      this.selected = this.createSelectedModel(this.current_km_data);
    }
  },
  async updated() {},
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
